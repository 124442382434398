<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      height="80"
      color="light"
      dark
    >
      <div class="brand">
        <h1> Swa<span class="colored-brand">Van</span> </h1>
      </div>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          height="80"
          dark
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            active-class="text-primary"
            class="font-weight-bold"
            min-width="100"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        'Home',
        'About',
        'Contact',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none

  h1
    padding: 10px
    font-size: 35px
    font-weight: bold
  .colored-brand
    background: rgb(27 127 204 / 94%)
</style>
